import {TimelineLite} from 'gsap'
import $ from 'jquery'

const clipPathHandler = () => {
  const $trigger = $('.js-carousel__trigger')
  const $image = $('.js-carousel__image')
  const $content = $('.js-carousel__wrapper')
  const $logo = $('.header__logo')
  const $arrows = $('.js-carousel__arrows')
  const $dots = $('.slick-dots')
  const $copyright = $('.copyright')
  const $back = $('.js-carousel__back')

  const tl = new TimelineLite({
    paused: true,
    onReverseComplete: () => {
      $image.removeAttr('style')
      $content.removeAttr('style')
      $logo.removeAttr('style')
    }
  })

  tl.fromTo($image, 1,
      {webkitClipPath: 'circle(320px at calc(50% - 260px) 50%)'},
      {webkitClipPath: `circle(${$( window ).width()}px at calc(50% - 260px) 50%)`})
    .fromTo($content, 0.3, {opacity: 1, x: 0}, {opacity: 0, x: 20}, "-=0.8")
    .fromTo($logo, 0.3, {opacity: 1, y: 0}, {opacity: 0, y: -20}, "-=0.5")
    .fromTo($back, 0.3, {opacity: 0.1}, {opacity: 1}, "-=0.5")
    .fromTo($dots, 0.3, {opacity: 1, x: 0}, {opacity: 0, x: -20}, "-=0.5")
    .fromTo($copyright, 0.3, {opacity: 1, y: 0}, {opacity: 0, y: -20}, "-=0.3")
    .fromTo($arrows, 0.5, {opacity: 1, x: 0}, {opacity: 0, x: -20})

  $trigger.hover(
    () => {
      tl.play().timeScale(1)
    },
    () => {
      tl.reverse().timeScale(2)
    })
}

export default  clipPathHandler;
