import $ from 'jquery'

import langSwitcher from './components/lang-switcher'
import detectLang from './components/detect-lang'
import carousel from './components/carousel'
import contactModalHandler from './components/contact-modal'
import clipPathHandler from './components/clip-path-handler'
import splashScreen from './components/splash-screen'

langSwitcher();
detectLang();
carousel();
contactModalHandler();

window.addEventListener('DOMContentLoaded', () => {
  const preloader = document.querySelector('.js-preloader')
  setTimeout(()=>{
    preloader.classList.add('isHidden')
    if($(window).width() >= 1200) {
      splashScreen();
      clipPathHandler();
    }
  }, 2000)
});
