const contactModalHandler = () => {
  const launcher = document.querySelector('.js-header__contact')
  const close = document.querySelector('.js-contacts-close')
  const contactWindow = document.querySelector('.js-contacts')

  launcher.addEventListener('click', ()=>{
    contactWindow.classList.add('isOpen')
  })

  close.addEventListener('click', ()=>{
    contactWindow.classList.remove('isOpen')
  })
}

export default contactModalHandler;
