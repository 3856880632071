import $ from 'jquery'
import 'slick-slider'

const carousel = () => {
  const $status = $('.js-paging-info')
  const $carousel = $('.js-carousel')

  $carousel.on('init reInit afterChange',(event, slick, currentSlide) => {
    const i = (currentSlide || 0) + 1;
    const pages = slick.slideCount < 10 ? `0${slick.slideCount}` : slick.slideCount

    $status.html(`
      <span class="paging-info__current">${i < 10 ? `0${i}` : i}</span> 
      <span class="paging-info__slash">/</span>
      <span class="paging-info__total">${pages}</span>
    `);
  });

  $carousel.on('init reInit',() => {
    const $nextButton = $('.carousel__button--next')
    const $arrows = $('.slick-arrow')

    if($(window).width() >= 1200) {
      $nextButton.addClass('isHovered')
      $arrows.hover(
        (e) => {
          $(e.target).addClass('isHovered').siblings().removeClass('isHovered')
        }
      )
    }
  });

  $carousel.slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    prevArrow: '<button type="button" class="carousel__button carousel__button--prev "><img src="/img/arrowLeft.svg" /></button>',
    nextArrow: '<button type="button" class="carousel__button carousel__button--next"><img src="/img/arrowLeft.svg" /></button>',
    appendArrows: $('.js-carousel__arrows'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: false,
        }
      },
    ]
  });
}

export default carousel;
