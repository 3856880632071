const langSwitcher = () => {
  const enLang = document.getElementById('en-lang');
  const uaLang = document.getElementById('ua-lang');

  const enLangHandler = () => {
    const location = window.location.pathname;
    const isUaLocation = location.split('/').includes('ua');

    if(isUaLocation) {

      if(location === '/ua/index.html') {
        const enLocation = location.replace('/ua/index.html', '');
        window.location.pathname = enLocation;
      } else {
        const enLocation = location.replace('/ua', '');
        window.location.pathname = enLocation;
      }
    }
  };

  const uaLangHandler = () => {
    const location = window.location.pathname;
    const isUaLocation = location.split('/').includes('ua');

    if(!isUaLocation) {
      if(location === '/') {
        const uaLocation = `${window.location.origin}/ua${window.location.pathname}index.html`;
        window.location.href = uaLocation;
      } else {
        const uaLocation = `${window.location.origin}/ua${window.location.pathname}`;
        window.location.href = uaLocation;
      }
    }
  };

  enLang.addEventListener('click', enLangHandler);
  uaLang.addEventListener('click', uaLangHandler);
};

export default langSwitcher;
