import {TimelineLite} from 'gsap'
import $ from 'jquery'

const splashScreen = () => {

  const $header = $('.header')
  const $image = $('.js-carousel__image')
  const $pagination = $('.js-paging-info')
  const $logo = $('.header__logo')
  const $title = $('.carousel__title')
  const $text = $('.carousel__text')
  const $dots = $('.slick-dots')
  const $arrows = $('.js-carousel__arrows')
  const $copyright = $('.copyright')

  const tl = new TimelineLite({
    onComplete: () => {
      $header.removeAttr('style')
      $image.removeAttr('style')
      $pagination.removeAttr('style')
      $logo.removeAttr('style')
      $title.removeAttr('style')
      $text.removeAttr('style')
    }
  })

  tl
    .fromTo($header, 0.9, {opacity: 0, y: -24}, {opacity: 1, y: 0})
    .fromTo($image, 0.6,
      {webkitClipPath: `circle(${$( window ).width()}px  at calc(50% - 260px) 50%)`},
      {webkitClipPath: 'circle(320px at calc(50% - 260px) 50%)'}, "-=0.3")
    .fromTo($pagination, 0.6, {opacity: 0, x: -24}, {opacity: 1, x: 0}, "-=0.3")
    .fromTo($logo, 0.6, {opacity: 0}, {opacity: 1}, "-=0.6")
    .fromTo($title, 0.6, {opacity: 0, x: 48}, {opacity: 1, x: 0}, "-=0.3")
    .fromTo($text, 0.6, {opacity: 0, x: 48}, {opacity: 1, x: 0}, "-=0.3")
    .fromTo($dots, 0.6, {opacity: 0, x: 48}, {opacity: 1, x: 0})
    .fromTo($copyright, 0.6, {opacity: 0, x: 48}, {opacity: 1, x: 0})
    .fromTo($arrows, 0.6, {opacity: 0, x: 48}, {opacity: 1, x: 0}, "-=0.3")
}

export default splashScreen
