const detectLang = () => {
  const enLang = document.getElementById('en-lang');
  const uaLang = document.getElementById('ua-lang');

  window.addEventListener('DOMContentLoaded', () => {
    if(!window.location.pathname.split('/').includes('ua')) {
      enLang.classList.add('isActive')
      uaLang.classList.remove('isActive')
    } else {
      uaLang.classList.add('isActive')
      enLang.classList.remove('isActive')
    }
  });
}
export default detectLang;
